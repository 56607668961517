<template>
  <div class="content-countermat common-department">
    <br>
    <span class="text-common" style="font-weight: bold">Check the box below if you would like to remove the countermat or vision area comparison button from the results screens.</span>
    <br>
    <br>
    <div>
      <span class="text-common">Important: Please assign the default products below, regardless of the countermat's status.</span>
      <br>
      <br>
      <div class="d-flex justify-content-start">
        <label class="check-box" :class="{ dis_checkbox: editable == false }">
          <input v-model="removeBtnOption" type="checkbox" /> Remove countermat from recommendation screen and homepage
          <span class="checkmark" />
        </label>
      </div>
      <div class="d-flex justify-content-start">
        <label class="check-box" :class="{ dis_checkbox: editable == false }">
          <input v-model="removeVisionOption" type="checkbox" /> Remove Vision comparison button from countermat
          <span class="checkmark" />
        </label>
      </div>
    </div>
    <hr style="border: 1px solid black; margin: 30px 0px 25px 0px;">
    <div class="parent">
      <div class="area" :class="{cover:removeBtnOption}"></div>
      <div id="area" class="area">
        <b-row class="mb-1">
          <b-col sm="2">
            <span class="rank-title">Rank name</span>
          </b-col>
          <b-col sm="3">
            <span class="rank-title">Single Vision Image</span>
            <p class="rank-desc">
              .jpeg or .png files only. Max 1MB. Dimension 1430 X 1949px
            </p>
          </b-col>
          <b-col sm="3">
            <span class="rank-title">Progressive Lens Image</span>
            <p class="rank-desc">
              .jpeg or .png files only. Max 1MB. Dimension 1430 X 1949px
            </p>
          </b-col>
        </b-row>
        <b-row
          v-for="(n, index) in 3"
          :key="index"
          class="mb-1"
          style="padding-top:2px"
        >
          <b-col
            sm="2"
            class="my-auto text-elipcise"
          >
            <template v-if="!editable">
              <CustomEditor v-model="ranks[index].name" span-class-name="text-common" />
            </template>
            <template v-else>
              <CustomEditor 
                v-model="ranks[index].name"
                type="textarea"
                rows="1"
                class-name="form-control"
                :maxlength="10"
              />
              <!-- <div class="main">
                <textarea-autosize
                  v-model="ranks[index].name"
                  rows="1"
                  type="text"
                  class="form-control"
                  :maxlength="10"
                />
                <span>{{ ranks[index].name.length }}/{{ 10 }}</span>
              </div> -->
            </template>
          </b-col>
          <b-col sm="3">
            <template v-if="!editable">
              <div :id="`popover-image-${singleVisionActionType}-${index}`" class="img-countermat">
                <img
                  :src="IMAGE_API_PATH + singleVisionCategoryRank[index].image"
                  fluid
                  alt="Responsive image"
                >
              </div>
              <ImagePopover 
                :product-type="singleVisionActionType" 
                :current-row-index="index" 
                :current-name="ranks[index].name" 
                :current-image="IMAGE_API_PATH + singleVisionCategoryRank[index].image" 
              />
            </template>
            <template v-else>
              <img
                width="68px"
                height="68px"
                :style="'border: 0.5px solid #848484'"
                :src="IMAGE_API_PATH + singleVisionCategoryRank[index].image"
                fluid
                alt="Responsive image"
              >
              <img
                v-if="editable"
                class="img-replace"
                src="@/assets/icons/replace.png"
                fluid
                alt="Replace"
                @click="replaceSVImage(index)"
              >
              <input
                ref="svImage"
                class="choose-file"
                type="file"
                @change="chooseSVImage($event)"
              >
            </template>
          </b-col>
          <b-col sm="3">
            <template v-if="!editable">
              <div :id="`popover-image-${progressiveActionType}-${index}`" class="img-countermat">
                <img
                  :src="IMAGE_API_PATH + progressiveLensCategoryRank[index].image"
                  fluid
                  alt="Responsive image"
                >
              </div>
              <ImagePopover 
                :product-type="progressiveActionType" 
                :current-row-index="index" 
                :current-name="ranks[index].name" 
                :current-image="IMAGE_API_PATH + progressiveLensCategoryRank[index].image" 
              />
            </template>
            <template v-else>
              <img
                width="68px"
                height="68px"
                :style="'border: 0.5px solid #848484'"
                :src="IMAGE_API_PATH + progressiveLensCategoryRank[index].image"
                fluid
                alt="Responsive image"
              >
              <img
                v-if="editable"
                class="img-replace"
                src="@/assets/icons/replace.png"
                fluid
                alt="Replace"
                @click="replacePLImage(index)"
              >
              <input
                ref="plImage"
                class="choose-file"
                type="file"
                @change="choosePLImage($event)"
              >
            </template>
          </b-col>
        </b-row>
        <div style="margin-top: 35px; padding: 0;">
          <!--===================== MASK IMAGE ===========================-->
          <div>
            <span><strong>Masking  files used to distinguish near, intermediate, far distances:</strong></span>
            <br />
            <p style="margin-botton:8px 0px;">
              png files only, max. 1MB, dimension 500X100px.
            </p>
          </div>

          <b-row
            v-for="(n, index) in 3"
            :key="index"
            class="mb-1"
            style="padding-top:2px"
          >
            <b-col
              sm="2"
              class="my-auto"
            >
              <span class="text-common">
                {{ maskImages[index].maskType }}
              </span>
            </b-col>
            <b-col sm="3">
              <template v-if="!editable">
                <div :id="`popover-image-${maskImages[index].maskType}-${index}`" class="img-countermat">
                  <img
                    :src="IMAGE_API_PATH + maskImages[index].image"
                    fluid
                    alt="Responsive image"
                  >
                </div>
                <ImagePopover 
                  :product-type="maskImages[index].maskType" 
                  :current-row-index="index" 
                  :current-name="maskImages[index].name" 
                  :current-image="IMAGE_API_PATH + maskImages[index].image" 
                />
              </template>
              <template v-else>
                <img
                  width="68px"
                  height="68px"
                  :style="'border: 0.5px solid #848484'"
                  :src="IMAGE_API_PATH + maskImages[index].image"
                  fluid
                  alt="Responsive image"
                >
                <img
                  v-if="editable"
                  class="img-replace"
                  src="@/assets/icons/replace.png"
                  fluid
                  alt="Replace"
                  @click="replaceMaskImage(index)"
                >
                <input
                  ref="maskImage"
                  class="choose-file"
                  type="file"
                  @change="chooseMaskImage($event)"
                >
              </template>
            </b-col>
          </b-row>
        </div>
        <!--===================== MASK IMAGE END ===========================-->
      </div>
    </div>
    <hr style="border: 1px solid black; margin: 30px 0px 25px 0px;">
    <div class="parent">
      <div class="area" :class="{cover:removeBtnOption}"></div>
      <div id="area2" class="area">
        <div>
          <span class="text-common">The following products that are marked, will be the default products shown in each countermat</span>
        </div>
        <br>
        <SingleVisionModule v-if="renderComponent" />
        <br>
        <ProgressLensModule v-if="renderComponent" />
        <br>
        <VoilaModule v-if="renderComponent" />
      </div>
    </div>
    <!--######################### The Modal SAVE ALL ###########################################################################-->
    <div v-if="showModalSaveAll">
      <transition name="modal">
        <div class="modal-mask-confirm">
          <div class="modal-wrapper">
            <div
              class="modal-dialog"
              role="document"
            >
              <div class="modal-content custom-dialog-confirm">
                <div class="modal-body">
                  <div class="body-text-modal-confirm">
                    <p>
                      All changes will be saved.<br>
                      Select “confirm” to continue.
                    </p>
                  </div>
                  <div
                    class="btn-modal-confirm"
                  >
                    <button
                      type="button"
                      class="btn btn-info"
                      style="background:#006DB6; margin-right:20px; width: 127px; height: 40px;"
                      @click="save(); showModalSaveAll = false;"
                    >
                      Confirm
                    </button>
                    <button
                      type="button"
                      class="btn btn-light"
                      style="color:#006DB6; margin-left:20px; width: 127px; height: 40px;"
                      @click="showModalSaveAll = false"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!--######################### The Modal SAVE All END ###########################################################################-->
    
    <!--######################### The Modal CANCEL ###########################################################################-->
    <div v-if="showModalCancelChange">
      <transition name="modal">
        <div class="modal-mask-confirm">
          <div class="modal-wrapper">
            <div
              class="modal-dialog"
              role="document"
            >
              <div class="modal-content custom-dialog-confirm">
                <div class="modal-body">
                  <div class="body-text-modal-confirm">
                    <p>
                      All changes will be removed.<br>
                      Select “confirm” to continue.
                    </p>
                  </div>
                  <div
                    class="btn-modal-confirm"
                  >
                    <button
                      type="button"
                      class="btn btn-info"
                      style="background:#006DB6; margin-right:20px; width: 127px; height: 40px;"
                      @click="turnOffEditable(); showModalCancelChange = false; showModalEdit=false"
                    >
                      Confirm
                    </button>
                    <button
                      type="button"
                      class="btn btn-light"
                      style="color:#006DB6; margin-left:20px; width: 127px; height: 40px;"
                      @click="showModalCancelChange = false"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!--######################### The Modal CANCEL END ###########################################################################-->
    <!--######################### The Modal ERRORR ###########################################################################-->
    <div v-if="showError">
      <transition name="modal">
        <div class="modal-mask-confirm">
          <div class="modal-wrapper">
            <div
              class="modal-dialog"
              role="document"
            >
              <div class="modal-content custom-dialog-confirm">
                <div class="modal-body">
                  <div class="body-text-modal-confirm">
                    <p>
                      {{ message }}<br>
                    </p>
                  </div>
                  <div
                    class="btn-modal-confirm"
                  >
                    <button
                      type="button"
                      class="btn btn-light"
                      style="color:#006DB6; margin-left:20px; width: 127px; height: 40px;"
                      @click="showError = false"
                    >
                      OK
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!--######################### The Modal ERRORR END ###########################################################################-->
  </div>
</template>

<script>
import ArrayUtils from "../../common/ArrayUtils";
import Constants from '../../common/Constants';
import CustomEditor from "../../components/custom/CustomEditor.vue";
import { CONTEXT_PATH, LOAD_LARGE_IMAGE_API_PATH } from '../../constants/Paths';
import CategoryRankService from "../../service/CategoryRankService";
import MaskImageService from "../../service/MaskImageService";
import SurveyConfigurationService from "../../service/SurveyConfigurationService";
import UploadFileService from "../../service/UploadFileService";
import ImagePopover from "../common/ImagePopover.vue";
import ProgressLensModule from "../countermat/ProgressLensModule.vue";
import SingleVisionModule from "../countermat/SingleVisionModule.vue";
import VoilaModule from "../countermat/VoilaModule.vue";

export default {
  name: "Countermat",
  components: {
    SingleVisionModule,
    ProgressLensModule,
    VoilaModule,
    ImagePopover,
    CustomEditor
  },
  data() {
    const surveyId = this.$store.state.survey.id
    return {
      surveyId: surveyId <= 0 ? 1 : surveyId,
      IMAGE_API_PATH: CONTEXT_PATH + LOAD_LARGE_IMAGE_API_PATH,
      progress: 0,
      showError: false,
      editable: (this.$store.state.survey.isSurveyEditing = false) && false,
      ranks: [],
      maskImages: [],
      showModalSaveAll: false,
      showModalCancelChange: false,
      singleVisionActionType: "SINGLE_VISION",
      progressiveActionType: "PROGRESSIVE_LENS",
      renderComponent: true,
      singleVisionCategoryRank: [],
      progressiveLensCategoryRank: [],
      changeImageRownIndex: null,
      removeBtnOption: false,
      removeVisionOption: false,
    };
  },
  mounted() {
    this.fetchDefault();
    this.loadConfig()
  },
  methods: {
    forceRerender() {
      // Remove my-component from the DOM
      this.renderComponent = false;
      this.$nextTick(() => {
        // Add the component back in
        this.renderComponent = true;
      });
    },
    turnOnEditable() {
      this.editable = true;
      this.$store.state.survey.editingScreenSaveFunc = this.save;
    },
    turnOffEditable() {
      this.editable = false;
      this.fetchDefault();
      this.loadConfig();
    },
    /* ************************* Replace Image Single Vision ************************* */
    replaceSVImage(index) {
      this.changeImageRownIndex = index
      const elem = this.$refs.svImage[index];
      elem.click();
    },
    chooseSVImage(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length)
        return;
      this.createSVImage(files[0], e.target);
    },
    createSVImage(file, input) {
      var index = this.changeImageRownIndex
      var suffix = file['type'];
      if (suffix !== 'image/png' && suffix !== 'image/jpeg') {
        this.message = "The image format is incorrect, please upload the file in .png, .jpeg format";
        this.showError = true
        this.progress = 0;
        this.currentFile = undefined;
        input.value = null;
        return;
      }

      if (file.size > Constants.COUNTERMAT_SV_MAX_SIZE) {
        this.message = "the maximum size is 1MB, please check your image again";
        this.showError = true;
        this.progress = 0;
        this.currentFile = undefined;
        input.value = null;
        return;
      }

      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        var image = new Image();
        image.src = reader.result;
        image.onload = () => {
          if(image.width > Constants.COUNTERMAT_SV_MAX_WIDTH_UPLOAD || image.height > Constants.COUNTERMAT_SV_MAX_HEIGHT_UPLOAD) {
            this.message = "The maximum dimension is 1430x1949px, please check your image again";
            this.showError = true;
            this.progress = 0;
            this.currentFile = undefined;
            input.value = null;
            return;
          }else {
            let loader = this.$loading.show(); 
            UploadFileService.uploadLargeFileWithFileNamePrefix(file, "countermat_SV_rank_image","SV",  event => {
              this.progress = Math.round((100 * event.loaded) / event.total);
            })
            .then(response => {
              loader.hide();
              var imageName = response.data.name;
              this.singleVisionCategoryRank[index].image = imageName;
            })
            .catch((error) => {
              loader.hide();
              console.log("error"+ error)
              this.message = error
              this.showError = true
              this.progress = 0;
              this.currentFile = undefined;
              input.value = null;
            });
          }
        };
      };
      reader.onerror = function (error) {
        console.log("error"+ error);
        this.message = error;
        this.showError = true;
        this.progress = 0;
        this.currentFile = undefined;
        input.value = null;
        return;
      };
    },

    /* ************************* Replace Image Progressive Lens ************************* */
    replacePLImage(index) {
      this.changeImageRownIndex = index
      const elem = this.$refs.plImage[index];
      elem.click();
    },
    choosePLImage(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length)
        return;
      this.createPLImage(files[0], e.target);
    },
    createPLImage(file, input) {
      var index = this.changeImageRownIndex
      var suffix = file['type'];
      if (suffix !== 'image/png' && suffix !== 'image/jpeg') {
        this.message = "The image format is incorrect, please upload the file in .png, .jpeg format";
        this.showError = true
        this.progress = 0;
        this.currentFile = undefined;
        input.value = null;
        return;
      }
      if (file.size > Constants.COUNTERMAT_PAL_MAX_SIZE) {
        this.message = "the maximum size is 1MB, please check your image again";
        this.showError = true;
        this.progress = 0;
        this.currentFile = undefined;
        input.value = null;
        return;
      }

      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        var image = new Image();
        image.src = reader.result;
        image.onload = () => {
          if(image.width > Constants.COUNTERMAT_PAL_MAX_WIDTH_UPLOAD || image.height > Constants.COUNTERMAT_PAL_MAX_HEIGHT_UPLOAD) {
            this.message = "The maximum dimension is 1430x1949px, please check your image again";
            this.showError = true;
            this.progress = 0;
            this.currentFile = undefined;
            input.value = null;
            return;
          }else {
            let loader = this.$loading.show();
            UploadFileService.uploadLargeFileWithFileNamePrefix(file, "countermat_PAL_rank_image","PL",  event => {
              this.progress = Math.round((100 * event.loaded) / event.total);
              })
            .then(response => {
              loader.hide()
              var imageName = response.data.name;
              this.progressiveLensCategoryRank[index].image = imageName;
            })
            .catch((error) => {
                loader.hide();
                console.log("error"+ error);
                this.message = error;
                this.showError = true;
                this.progress = 0;
                console.log(this.message);
                this.currentFile = undefined;
                input.value = null;
              });
          }
        };
      };
      reader.onerror = function (error) {
        console.log("error"+ error);
        this.message = error;
        this.showError = true;
        this.progress = 0;
        this.currentFile = undefined;
        input.value = null;
        return;
      };

    },
    /* ************************* Replace Image MASK ************************* */
    replaceMaskImage(index) {
      this.changeImageRownIndex = index
      const elem = this.$refs.maskImage[index];
      elem.click();
    },
    chooseMaskImage(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length)
        return;
      this.createMaskImage(files[0], e.target);
    },
    createMaskImage(file, input) {
      var index = this.changeImageRownIndex
      var suffix = file['type'];
      if (suffix !== 'image/png') {
        this.message = "The image format is incorrect, please upload the file in .png format";
        this.showError = true;
        this.progress = 0;
        this.currentFile = undefined;
        input.value = null;
        return;
      }

      if (file.size > Constants.COUNTERMAT_MASK_MAX_SIZE) {
        this.message = "the maximum size is 1MB, please check your image again";
        this.showError = true;
        this.progress = 0;
        this.currentFile = undefined;
        input.value = null;
        return;
      }

      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        var image = new Image();
        image.src = reader.result;
        image.onload = () => {
          if(image.width > Constants.COUNTERMAT_MASK_MAX_WIDTH_UPLOAD || image.height > Constants.COUNTERMAT_MASK_MAX_HEIGHT_UPLOAD) {
            this.message = "The maximum dimension is 500x100px, please check your image again";
            this.showError = true;
            this.progress = 0;
            this.currentFile = undefined;
            input.value = null;
            return;
          }else {
            let loader = this.$loading.show();
            UploadFileService.uploadLargeFileWithFileNamePrefix(file, "countermat_Mask_image","MASK",  event => {
              this.progress = Math.round((100 * event.loaded) / event.total);
            })
            .then(response => {
              loader.hide()
              var imageName = response.data.name;
              this.maskImages[index].image = imageName;
            })
            .catch((error) => {
              loader.hide()
              console.log("error"+ error)
              this.message = error;
              this.showError = true;
              this.progress = 0;
              this.currentFile = undefined;
              input.value = null;
            });
          }
        };
      };
      reader.onerror = function (error) {
        console.log("error"+ error);
        this.message = error;
        this.showError = true
        this.progress = 0;
        this.currentFile = undefined;
        input.value = null;
        return;
      };
    },

    /* ************************* Replace Image Progressive Lens End************************* */
    fetchDefault() {
      let loader = this.$loading.show();
      let surveyId = this.$store.state.survey.id;
      this.surveyId = surveyId <= 0 ? 1 : surveyId;
      CategoryRankService.listByCategory(surveyId)
      .then(response => {
        var result = response.data.result;
        if(result) {
          this.ranks = result.ranks;
          this.singleVisionCategoryRank = result.singleVisionCategoryRank;
          this.progressiveLensCategoryRank = result.progressiveLensCategoryRank;
        }
      })
      .catch(e => {
        console.log(e);
      })

      MaskImageService.findBySurveyId(surveyId)
      .then(response => {
        var result = response.data.result;
        if(result) {
          this.maskImages = result.maskImages;
        }
        loader.hide();
      })
      .catch(e => {
        console.log(e);
        loader.hide();
      })
    },
    loadConfig() {
        SurveyConfigurationService.list(this.surveyId)
            .then(response => {
                var result = response.data.result
                var configs = result.configurations
                configs.forEach(config => {
                    if(config.configKey === Constants.COUNTERMAT_REMOVE_BTN_OPTION) {
                        this.removeBtnOption = (config.configValue == 'true')
                    }
                })
                configs.forEach(config => {
                    if(config.configKey === Constants.VISION_REMOVE_BUTTON_OPTION) {
                        this.removeVisionOption = (config.configValue == 'true')
                    }
                })
            })
            .catch(e => {
                this.updateMessage(e)
                this.updateShowError(true)
            })
    },
    saveSurveyConfiguration(chain) {
        /* Save config */
        var configs = []
        configs.push({"configKey" : Constants.COUNTERMAT_REMOVE_BTN_OPTION, "configValue": this.removeBtnOption, "surveyId" : this.surveyId});
        configs.push({"configKey" : Constants.VISION_REMOVE_BUTTON_OPTION, "configValue": this.removeVisionOption, "surveyId" : this.surveyId});
        SurveyConfigurationService.save(configs).then(() => {
          if(chain !== undefined)
            chain();
        });
    },
    /** Save all */
    save(chainFunc) {
      this.turnOffEditable();
      var categoryRanks = this.singleVisionCategoryRank;
      categoryRanks = categoryRanks.concat(this.progressiveLensCategoryRank);
      
      let chain1 = undefined;
      if (chainFunc !== undefined) {
        let completeCount = 0;
        chain1 = () => {
          completeCount ++;
          if (completeCount === 2)
            chainFunc();
        };
      }
      this.saveSurveyConfiguration(chain1);

      var data = {
        ranks: this.ranks,
        categoryRanks: categoryRanks,
        maskImages: this.maskImages,
        surveyId: this.surveyId
      };
      console.log(data)
      CategoryRankService.saveAll(data)
          .then(response => {
            console.log(response.data.result.numerEffected)
            this.editable = false
            this.fetchDefault()
            this.loadConfig()
            this.forceRerender()
            if (chain1 !== undefined)
              chain1()
          })
          .catch(e => {
            console.log(e);
            this.editable = false
            this.fetchDefault()
            this.loadConfig()
            this.forceRerender()
          });
    },
    assign() {},
    info() {},
    refresh() {
      this.items = ArrayUtils.clone(this.items);
    },
  },
}
</script>

<style scoped>
.choose-file {
    display: none;
}
.content-countermat {
  padding: 0px;
  padding-bottom: 50px;
}

.text-common {
  font-size: 15px;
  line-height: 23px;
  text-align: left;
  margin-bottom: 1rem;
  font-family: 'Montserrat', sans-serif;
  padding: 2px 0px;
}

.rank-title {
  font-weight: bold;
  font-size: 15px;
  line-height: 23px;
  text-align: left;
  margin-bottom: 1rem;
  font-family: 'Montserrat', sans-serif;
  padding: 2px 0px;
}

.btn-blue {
  border: 1px solid #006DB6;
  background-color: white;
  color: #006db6;
  height: 39px;
  width: 127px;
  left: -360px;
  top: -1798px;
  border-radius: 6px;
  padding: 8px, 16px, 8px, 16px;
  font-size: 15px;
  cursor: pointer;
}

.custom-btn {
  font-weight: 550;
  width: 127px;
  height: 39px;
  font-size: 15px;
  line-height: 23px;
  font-family: 'Montserrat', sans-serif;
}

.btn-save {
  margin-right: 10px;
}


.img-countermat {
  height: 68px;
  width: 68px;
}
.img-countermat > img {
  height: 68px;
  width: 68px;
  border: 0.5px solid #848484;
}
.img-replace {
  height: 18px;
  width: 18px;
  margin-left: 5px;
}
.margin-left {
  margin-left: 5px;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: table;
  transition: opacity .3s ease;
}

.modal-mask-confirm {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: hsla(0, 0%, 71%, 0.5);
  display: table;
  transition: opacity .3s ease;
}

.custom-modal-dialog {
  margin: 0 auto !important;
  max-width: 100% !important;
  display: flex;
  justify-content: center;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.custom-dialog {
  width: 1034px;
  height: 602px;
  background: white;
}

.custom-dialog-confirm {
  width: 467px;
  height: 283px;
  background: #4B4B4B;
}

.modal-custom-header {
  background: #E5F4FF;
  margin: 0px 0px 15px 0px;
  padding: 10px;
}

.modal-custom-body {
  background: white;
  margin: 0px;
  padding: 0px 25px;
}

.modal-custom-body-row {
  background: #f6f6f6;
  margin: 0px 25px;
  padding: 0px;
}

.modal-custom-product {
  background: white;
  margin: 0px 25px;
  min-height: 150px;
}

.body-text-modal{
  text-align: left;
  font-size: 15px;
  line-height: 23px;
  color: black;
}

.body-text-modal-confirm{
  width: 294px;
  height: 56px;
  margin: 0 auto;
  text-align: center;
  padding: 60px 0px;
  margin-bottom: 20px;
  font-size: 15px;
  line-height: 25px;
  color: white;
}

.btn-modal {
  float: right;
}

.btn-modal-confirm{
  display: flex;
  justify-content: center;
}
.dis_checkbox {
  pointer-events: none;
}
.clear{
  clear: both;
}
.parent{
  position: relative;
}
.cover{
  position: absolute;
  height: 100%;
  width: 100%;
  display: block;
  z-index: 1005;
  background: rgba(255, 255, 255, 0.8);
}
.text-elipcise {
  word-break: break-word;
  overflow: hidden;
   display: -webkit-box;
   -webkit-line-clamp: 3; /* number of lines to show */
           line-clamp: 3; 
   -webkit-box-orient: vertical;
}

.main {
  position: relative;
  display: inline-block;
  border: 1px solid #d8dbe0;
  border-radius: 0.25rem;
  background-color: #fff;
  width: 100%;
}
.main span {
  color: #768192;
  position: absolute;
  bottom: .375rem;
  right: .75rem;
  font-size: 10px;
}

.main textarea {
  border: none!important;
  margin-bottom: 20px;
}

.main textarea:focus, .main textarea:active {
  border: none;
  overflow: auto;
  outline: none;

  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;

  resize: none; /*remove the resize handle on the bottom right*/
}

</style>
