<template>
  <div>
    <div class="common-department" :style="{top: headerHeight + 'px'}">
      <div class="row align-items-start" style="margin:0; padding:0">
        <div class="col-5" style="margin:0; padding:0">
          <table
            class="table-sm table custom-table"
            style="width: 100%"
            responsive
            sm
            borderless
          >
            <thead>
              <tr bgcolor="#F6F6F6">
                <th width="30%" class="th-text">
                  CATEGORY
                </th>
                <th width="15%" class="th-text">
                  DEFAULT
                </th>
                <th
                  width="55%" class="th-text"
                >
                  <div class="text-elipcise">
                    <!-- {{ bestRankTitle }} -->
                    <CustomEditor v-model="bestRankTitle" />
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <template v-if="spanSize == 0">
                <tr>
                  <td
                    :rowspan="1"
                    class="s"
                  >
                    <strong style="color: #006db6">
                      {{ singleVisionCategory.name }}
                    </strong>
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr
                  v-for="(item, index) in singleVisionBest"
                  :key="item.id"
                >
                  <td
                    v-if="index === 0"
                    :rowspan="spanSize"
                    class="s"
                  >
                    <strong style="color: #006db6">{{ singleVisionCategory.name }}
                    </strong>
                  </td>
                  <td
                    :class="{ 'border-top': index > 0 }"
                    class="text-center"
                  >
                    <div v-if="item.default == true" class="radio-item">
                      <input id="ritema" type="radio" name="radio-options-SV-best" value="item.name" checked />
                      <label for="ritema"></label>
                    </div>
                  </td>
                  <td
                    :class="{ 'border-top': index > 0 }"
                  >
                    <!-- {{ item.name }} -->
                    <CustomEditor v-model="item.name" />
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
        <div class="col-3" style="margin:0; padding:0">
          <table
            class="table-sm table custom-table"
            style="width: 100%"
            responsive
            sm
            borderless
          >
            <thead>
              <tr bgcolor="#F6F6F6">
                <th width="20%" class="th-text">
                  DEFAULT
                </th>
                <th
                  width="80%" class="th-text"
                >
                  <div class="text-elipcise">
                    <!-- {{ betterRankTitle }} -->
                    <CustomEditor v-model="betterRankTitle" />
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in singleVisionBetter"
                :key="item.id"
              >
                <td
                  :class="{ 'border-top': index > 0 }"
                  class="text-center"
                >
                  <div v-if="item.default == true" class="radio-item">
                    <input id="ritema" type="radio" name="radio-options-SV-better" value="item.name" checked />
                    <label for="ritema"></label>
                  </div>
                </td>
                <td
                  :class="{ 'border-top': index > 0 }"
                >
                  <!-- {{ item.name }} -->
                  <CustomEditor v-model="item.name" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-4" style="margin:0; padding:0">
          <table
            class="table-sm table custom-table"
            style="width: 100%"
            responsive
            sm
            borderless
          >
            <thead>
              <tr bgcolor="#F6F6F6">
                <th width="15%" class="th-text">
                  DEFAULT
                </th>
                <th
                  width="55%" class="th-text"
                >
                  <div class="text-elipcise">
                    <!-- {{ goodRankTitle }} -->
                    <CustomEditor v-model="goodRankTitle" />
                  </div>
                </th>
                <th
                  width="30%"
                >
                  <b-button 
                    variant="outline-info custom-btn"
                    style="height: 36px !important; float:right; margin-right: 4%"
                    @click="showModalEdit=true"
                  >
                    Edit content
                  </b-button>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in singleVisionGood"
                :key="item.id"
              >
                <td
                  :class="{ 'border-top': index > 0 }"
                  class="text-center"
                >
                  <div v-if="item.default == true" class="radio-item">
                    <input id="ritema" type="radio" name="radio-options-SV-good" value="item.name" checked />
                    <label for="ritema"></label>
                  </div>
                </td>
                <td
                  :class="{ 'border-top': index > 0 }"
                >
                  <!-- {{ item.name }} -->
                  <CustomEditor v-model="item.name" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    
    <!--######################### The Modal edit ###########################################################################-->
    <div v-if="showModalEdit">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div
              class="modal-dialog custom-modal-dialog"
              role="document"
            >
              <div class="modal-content custom-dialog">
                <div class="modal-body" style="padding:0px; margin:0px 0px; overflow:auto;">
                  <b-row class="modal-custom-header">
                    <b-col
                      sm="8"
                      class="my-auto"
                    >
                      <div class="body-text-modal">
                        <strong>{{ singleVisionCategory.name }}</strong><br>
                        <strong>STEP 1: </strong><span>Select 1 category to begin: Best, Better or Good</span><br>
                        <strong>STEP 2: </strong><span>Click to add products.</span><br>
                        <strong>STEP 3: </strong><span>Mark 1 product in each category, to be displayed on the default countermat</span>
                      </div>
                    </b-col>
                    <b-col
                      sm="4"
                      class="my-auto"
                    > 
                      <div>
                        <div
                          class="btn-modal"
                        >
                          <b-button
                            type="button"
                            variant="outline-info custom-btn btn-save"
                            class="btn btn-info"
                            style="margin-right:10px;"
                            @click="saveOnclick();"
                          >
                            Save
                          </b-button>
                          <b-button
                            type="button"
                            variant="outline-info custom-btn"
                            class="btn btn-light"
                            @click="showModalCancelChange = true"
                          >
                            Cancel
                          </b-button>
                        </div>
                        <div class="clear"></div>
                        <div v-if="hasError" class="warning-text">
                          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.00977 0.726562C4.43555 0.726562 0.728516 4.43359 0.728516 9.00781C0.728516 13.582 4.43555 17.2891 9.00977 17.2891C13.584 17.2891 17.291 13.582 17.291 9.00781C17.291 4.43359 13.584 0.726562 9.00977 0.726562ZM9.97656 12.9434C9.97656 13.209 9.86523 13.4434 9.69141 13.6348C9.51562 13.8262 9.25391 13.9102 9 13.9199C8.75586 13.9316 8.47852 13.8027 8.30859 13.6348C8.13086 13.457 8.02344 13.1953 8.02344 12.9434V8.90039C8.02344 8.63672 8.13477 8.40039 8.30859 8.21094C8.48438 8.01953 8.74609 7.93555 9 7.92383C9.24414 7.91211 9.52149 8.04102 9.69141 8.21094C9.86914 8.38867 9.97656 8.65039 9.97656 8.90039V12.9434ZM9.97656 5.32617V5.36523C9.97656 5.54102 9.93164 5.70508 9.84375 5.85742C9.79297 5.92383 9.74219 5.98828 9.69141 6.05469C9.57031 6.17578 9.42578 6.25977 9.25977 6.30664C9.17383 6.31836 9.08594 6.33008 9 6.3418C8.86523 6.34766 8.74023 6.31836 8.62305 6.25781C8.50195 6.21875 8.39648 6.15039 8.30859 6.05664C8.21289 5.96875 8.14648 5.86328 8.10742 5.74219C8.04688 5.625 8.01758 5.5 8.02344 5.36523V5.32617C8.02344 5.15039 8.06836 4.98633 8.15625 4.83398C8.20703 4.76758 8.25781 4.70313 8.30859 4.63672C8.42969 4.51563 8.57422 4.43164 8.74023 4.38477C8.82617 4.37305 8.91406 4.36133 9 4.34961C9.13477 4.34375 9.25977 4.37305 9.37695 4.43359C9.49805 4.47266 9.60352 4.54102 9.69141 4.63477C9.78711 4.72266 9.85352 4.82813 9.89258 4.94922C9.95312 5.06641 9.98242 5.19141 9.97656 5.32617Z" fill="#FF0000" />
                          </svg>
                          <span style="padding-left: 5px">{{ errorMessage }}</span>
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                  <div>
                    <div class="row align-items-start modal-custom-body">
                      <div class="col border-secondary category-btn d-flex justify-content-center align-items-center">
                        <button
                          type="button"
                          :class="{'btn btn-primary choose-color': chooseBest,'btn btn-outline-info': !chooseBest}"
                          @click="chooseBestClick()"
                        >
                          <div class="text-elipcise-2">
                            <!-- {{ bestRankTitle }} -->
                            <CustomEditor v-model="bestRankTitle" />
                          </div>
                        </button>
                      </div>
                      <div class="col border-secondary category-btn d-flex justify-content-center align-items-center">
                        <button
                          type="button"
                          :class="{'btn btn-primary choose-color': chooseBetter,'btn btn-outline-info': !chooseBetter}"
                          @click="chooseBetterClick()"
                        >
                          <div class="text-elipcise-2">
                            <!-- {{ betterRankTitle }} -->
                            <CustomEditor v-model="betterRankTitle" />
                          </div>
                        </button>
                      </div>
                      <div class="col border-secondary category-btn d-flex justify-content-center align-items-center">
                        <button
                          type="button"
                          :class="{'btn btn-primary choose-color': chooseGood,'btn btn-outline-info': !chooseGood}"
                          @click="chooseGoodClick()"
                        >
                          <div class="text-elipcise-2">
                            <!-- {{ goodRankTitle }} -->
                            <CustomEditor v-model="goodRankTitle" />
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div class="row align-items-start modal-custom-body-row">
                      <div class="col border-secondary"
                           style="background: #F6F6F6"
                      >
                        <table
                          class="table-sm table custom-table"
                          style="width: 100%"
                          responsive
                          sm
                          borderless
                        >
                          <thead>
                            <tr bgcolor="#F6F6F6">
                              <th width="30%" class="th-text">
                                DEFAULT
                              </th>
                              <th
                                width="60%" class="th-text"
                              >
                                PRODUCT
                              </th>
                              <th
                                width="10%" class="th-text"
                              >
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(item, index) in singleVisionBest"
                              :key="item.id"
                            >
                              <td
                                class="text-center"
                              >
                                <div class="radio-item" @click="radioBestOnclick(index)">
                                  <input id="ritema" type="radio" :checked="item.default == true" />
                                  <label for="ritema"></label>
                                </div>
                              </td>
                              <td>
                                <div class="div-elipcise">
                                  <!-- {{ item.name }} -->
                                  <CustomEditor v-model="item.name" />
                                </div>
                              </td>
                              <td>
                                <b-icon
                                  class="pointer"
                                  icon="x-circle"
                                  variant="danger"
                                  @click="removeProduct(index, item); removeRankType=bestRankType"
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div class="col border-secondary"
                           style="background: #F6F6F6"
                      >
                        <table
                          class="table-sm table custom-table"
                          style="width: 100%"
                          responsive
                          sm
                          borderless
                        >
                          <thead>
                            <tr bgcolor="#F6F6F6">
                              <th width="30%" class="th-text">
                                DEFAULT
                              </th>
                              <th
                                width="60%" class="th-text"
                              >
                                PRODUCT
                              </th>
                              <th
                                width="10%" class="th-text"
                              >
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(item, index) in singleVisionBetter"
                              :key="item.id"
                            >
                              <td
                                class="text-center"
                              >
                                <div class="radio-item" @click="radioBetterOnclick(index)">
                                  <input id="ritema" type="radio" :checked="item.default == true" />
                                  <label for="ritema"></label>
                                </div>
                              </td>
                              <td>
                                <div class="div-elipcise">
                                  <!-- {{ item.name }} -->
                                  <CustomEditor v-model="item.name" />
                                </div>
                              </td>
                              <td>
                                <b-icon
                                  class="pointer"
                                  icon="x-circle"
                                  variant="danger"
                                  @click="removeProduct(index, item); removeRankType=betterRankType"
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div class="col border-secondary"
                           style="background: #F6F6F6"
                      >
                        <table
                          class="table-sm table custom-table"
                          style="width: 100%"
                          responsive
                          sm
                          borderless
                        >
                          <thead>
                            <tr bgcolor="#F6F6F6">
                              <th width="30%" class="th-text">
                                DEFAULT
                              </th>
                              <th
                                width="60%" class="th-text"
                              >
                                PRODUCT
                              </th>
                              <th
                                width="10%" class="th-text"
                              >
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(item, index) in singleVisionGood"
                              :key="item.id"
                            >
                              <td
                                class="text-center"
                              >
                                <div class="radio-item" @click="radioGoodOnclick(index)">
                                  <input id="ritema" type="radio" :checked="item.default == true" />
                                  <label for="ritema"></label>
                                </div>
                              </td>
                              <td>
                                <div class="div-elipcise">
                                  <!-- {{ item.name }} -->
                                  <CustomEditor v-model="item.name" />
                                </div>
                              </td>
                              <td>
                                <b-icon
                                  class="pointer"
                                  icon="x-circle"
                                  variant="danger"
                                  @click="removeProduct(index, item); removeRankType=goodRankType"
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div style="margin-top: 30px; margin-bottom: 30px">
                    <div class="row align-items-start modal-custom-product border-secondary">
                      <b-col
                        sm="1"
                        style="padding: 6px 5px; margin: 0;"
                      >
                        <strong>Products</strong>
                      </b-col>
                      <b-col
                        sm="11"
                        style="margin:0; padding: 0"
                      >
                        <table
                          class="table-sm table table-hover custom-table"
                          style="width: 100%"
                          responsive
                          sm
                          borderless
                        >
                          <tbody>
                            <tr
                              v-for="(item, index) in singleVisionProductNotInCountermats"
                              :key="item.id"
                            >
                              <td width="90%" :style="'word-break: break-word;'">
                                <!-- {{ item.name }} -->
                                <CustomEditor v-model="item.name" />
                              </td>
                              <td width="10%">
                                <button
                                  type="button"
                                  class="btn btn-info btn-add w-100"
                                  @click="setRankForProduct(index)"
                                >
                                  Add
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </b-col>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!--######################### The Modal Edit All END ###########################################################################-->
    <!--######################### The Modal SAVE ALL ###########################################################################-->
    <div v-if="showModalSaveAll">
      <transition name="modal">
        <div class="modal-mask-confirm">
          <div class="modal-wrapper">
            <div
              class="modal-dialog"
              role="document"
            >
              <div class="modal-content custom-dialog-confirm">
                <div class="modal-body">
                  <div class="body-text-modal-confirm">
                    <p>
                      All changes will be saved.<br>
                      Select “confirm” to continue.
                    </p>
                  </div>
                  <div
                    class="btn-modal-confirm"
                  >
                    <button
                      type="button"
                      class="btn btn-info"
                      style="background:#006DB6; margin-right:20px; width: 127px; height: 40px;"
                      @click="saveAll(); showModalSaveAll = false; showModalEdit = false;"
                    >
                      Confirm
                    </button>
                    <button
                      type="button"
                      class="btn btn-light"
                      style="color:#006DB6; margin-left:20px; width: 127px; height: 40px;"
                      @click="showModalSaveAll = false"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!--######################### The Modal SAVE All END ###########################################################################-->
    
    <!--######################### The Modal CANCEL ###########################################################################-->
    <div v-if="showModalCancelChange">
      <transition name="modal">
        <div class="modal-mask-confirm">
          <div class="modal-wrapper">
            <div
              class="modal-dialog"
              role="document"
            >
              <div class="modal-content custom-dialog-confirm">
                <div class="modal-body">
                  <div class="body-text-modal-confirm">
                    <p>
                      All changes will be removed.<br>
                      Select “confirm” to continue.
                    </p>
                  </div>
                  <div
                    class="btn-modal-confirm"
                  >
                    <button
                      type="button"
                      class="btn btn-info"
                      style="background:#006DB6; margin-right:20px; width: 127px; height: 40px;"
                      @click="reloadPage(); showModalCancelChange = false; showModalEdit=false"
                    >
                      Confirm
                    </button>
                    <button
                      type="button"
                      class="btn btn-light"
                      style="color:#006DB6; margin-left:20px; width: 127px; height: 40px;"
                      @click="showModalCancelChange = false"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!--######################### The Modal CANCEL END ###########################################################################-->
    
    <!--######################### The Modal DELETE ###########################################################################-->
    <div v-if="showModalDelete">
      <transition name="modal">
        <div class="modal-mask-confirm">
          <div class="modal-wrapper">
            <div
              class="modal-dialog"
              role="document"
            >
              <div class="modal-content custom-dialog-confirm">
                <div class="modal-body">
                  <div class="body-text-modal-confirm">
                    <p>
                      {{ titleDeletedProduct }} will be deleted.<br>
                      Select “confirm” to continue. 
                    </p>
                  </div>
                  <div
                    class="btn-modal-confirm"
                  >
                    <button
                      type="button"
                      class="btn btn-info"
                      style="background:#006DB6; margin-right:20px; width: 127px; height: 40px;"
                      @click="showModalDelete = false; removeConfirm()"
                    >
                      Confirm
                    </button>
                    <button
                      type="button"
                      class="btn btn-light"
                      style="color:#006DB6; margin-left:20px; width: 127px; height: 40px;"
                      @click="showModalDelete = false"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!--######################### The Modal DELETE END ###########################################################################-->
  </div>
</template>

<script>
import ArrayUtils from "../../common/ArrayUtils";
import CustomEditor from "../../components/custom/CustomEditor.vue";
import CountermatService from "../../service/CountermatService";

export default {
  name: "SingleVisionModule",
  components: {CustomEditor},
  props: {
    reload: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      editable: false,
      ranks: [],
      showModalEdit: false,
      showModalCancelChange: false,
      showModalDelete: false,
      showModalSaveAll: false,
      dataTable: [],
      singleVisionCategory: {},
      singleVisionCountermat: [],
      singleVisionBest: [],
      singleVisionBetter: [],
      singleVisionGood: [],
      spanSize: 0,
      singleVisionProductNotInCountermats: [],

      chooseBest: true,
      chooseBetter: false,
      chooseGood: false,

      bestRankTitle: "BEST",
      betterRankTitle: "BETTER",
      goodRankTitle: "GOOD",
      bestRankType: "BEST",
      betterRankType: "BETTER",
      goodRankType: "GOOD",

      removeRankType: "",
      titleDeletedProduct: "",
      removeIndex: "",
      removeProductItem: {},

      hasError: false,
      errorMessage: ""
    };
  },
  computed: {
    headerHeight() {
      return this.$store.state.common.theHeaderHeight
    }
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    saveOnclick() {
      if (this.validateData()) {
        this.showModalSaveAll = true
      }
    },
    reloadPage() {
      this.fetchData();
      this.chooseBestClick();
      this.hasError = false;
    },
    fetchData() {
      let surveyId = this.$store.state.survey.id;
      this.surveyId = surveyId <= 0 ? 1 : surveyId;
      CountermatService.listProductCountermatSingleVision(surveyId)
        .then(response => {
          var result = response.data.result;
          if(result) {
            this.ranks = result.ranks;
            if(this.ranks) {
              this.bestRankTitle = this.ranks[0].name.toUpperCase();
              this.betterRankTitle = this.ranks[1].name.toUpperCase();
              this.goodRankTitle = this.ranks[2].name.toUpperCase();
            }
            this.singleVisionCategory = result.singleVisionCategory;
            this.singleVisionCountermat = result.singleVisionCountermat;

            this.singleVisionBest = this.singleVisionCountermat.singleVisionBest
            this.singleVisionBetter = this.singleVisionCountermat.singleVisionBetter
            this.singleVisionGood = this.singleVisionCountermat.singleVisionGood
            this.singleVisionProductNotInCountermats = this.singleVisionCountermat.singleVisionProductNotInCountermats;

            if (this.singleVisionBest) {
              this.spanSize = (this.spanSize > this.singleVisionBest.length) ? this.spanSize : this.singleVisionBest.length
              console.log("this.spanSize" + this.spanSize)
            }
            if (this.singleVisionBetter) {
              this.spanSize = (this.spanSize > this.singleVisionBetter.length) ? this.spanSize : this.singleVisionBetter.length
              console.log("this.spanSize" + this.spanSize)
            }
            if (this.singleVisionGood) {
              this.spanSize = (this.spanSize > this.singleVisionGood.length) ? this.spanSize : this.singleVisionGood.length
              console.log("this.spanSize" + this.spanSize)
            }
            console.log("ranks" + this.ranks);
            console.log("singleVisionCountermat" + this.singleVisionCountermat);
            console.log("singleVisionBest " + this.singleVisionBest);
            console.log("singleVisionBetter " + this.singleVisionBetter);
            console.log("singleVisionGood " + this.singleVisionGood);
          }
        })
        .catch(e => {
          console.log(e);
        })
    },

    /**
     * Set rank for product
    */
    setRankForProduct(index) {
      var slectedProduct = this.singleVisionProductNotInCountermats[index];
      if (this.chooseBest) {
        this.singleVisionBest.push(slectedProduct);
      }
      if (this.chooseBetter) {
        this.singleVisionBetter.push(slectedProduct);
      }
      if (this.chooseGood) {
        this.singleVisionGood.push(slectedProduct);
      }
      this.singleVisionProductNotInCountermats.splice(index,1);
      if (this.singleVisionProductNotInCountermats.length == 0) {
        this.hasError = false;
      }
    },

    /**
     * Remove product onclick
     */
    removeProduct(index, removeProduct) {
        this.showModalDelete = true
        this.titleDeletedProduct = removeProduct.name
        this.removeIndex = index
        this.removeProductItem = removeProduct
        this.removeProductItem.default = false
    },
    
    /**
     * Remove product confirm
     */
    removeConfirm() {
      console.log("this.removeRankType = " + this.removeRankType)
      if(this.removeRankType === this.bestRankType){
        this.singleVisionBest.splice(this.removeIndex,1);
        this.singleVisionProductNotInCountermats.push(this.removeProductItem);
        this.resetDefaultIfOnlyOneProdductExisted(this.singleVisionBest);
        this.removeProductItem = {};
      }
      if(this.removeRankType === this.betterRankType){
        this.singleVisionBetter.splice(this.removeIndex,1);
        this.singleVisionProductNotInCountermats.push(this.removeProductItem);
        this.resetDefaultIfOnlyOneProdductExisted(this.singleVisionBetter);
        this.removeProductItem = {};
      }
      if(this.removeRankType === this.goodRankType){
        this.singleVisionGood.splice(this.removeIndex,1);
        this.singleVisionProductNotInCountermats.push(this.removeProductItem);
        this.resetDefaultIfOnlyOneProdductExisted(this.singleVisionGood);
        this.removeProductItem = {};
      }
    },

    resetDefaultIfOnlyOneProdductExisted(rankingProducts) {
      if(rankingProducts !== null && rankingProducts.length == 1) {
        rankingProducts[0].default = true;
      }
    },

    /**
     * Save all data
     */
    saveAll() {
      var data = {
        productCountermatBest: this.singleVisionBest,
        productCountermatBetter : this.singleVisionBetter,
        productCountermatGood : this.singleVisionGood,
        productNotInCountermats : this.singleVisionProductNotInCountermats
      };

      CountermatService.saveProductCountermatSingleVision(data)
        .then(response => {
          console.log(response.data.result.numerEffectedProduct)
          this.showModalEdit = false
          this.fetchData()
          this.chooseBestClick()
        })
        .catch(e => {
          console.log(e);
          this.showModalEdit = false
          this.fetchData()
          this.chooseBestClick()
        });
      },

    validateData() {
      // Check assigned product
      if(this.singleVisionProductNotInCountermats.length > 0) {
        console.log("error here")
        this.hasError = true;
        this.errorMessage = "All products must be assigned."
        return false
      }

      // Check set default product
      if (this.hasDefaultProduct(this.singleVisionBest) === false) {
        console.log("error here")
        this.hasError = true;
        this.errorMessage = "Please assign default best product."
        return false
      }
      if (this.hasDefaultProduct(this.singleVisionBetter) === false) {
        console.log("error here")
        this.hasError = true;
        this.errorMessage = "Please assign default better product."
        return false
      }
      if (this.hasDefaultProduct(this.singleVisionGood) === false) {
        console.log("error here")
        this.hasError = true;
        this.errorMessage = "Please assign default good product."
        return false
      }

      this.hasError = false;
      return true
    },

    hasDefaultProduct(rankingProducts) {
      console.log("rankingProducts" + JSON.stringify(rankingProducts))
      if(rankingProducts == null || rankingProducts == undefined || rankingProducts.length == 0) {
        return true;
      }
      var hasDefault = false;
      rankingProducts.forEach(product => {
        if(product.default === true) {
          hasDefault = true;
        }
      })
      return hasDefault;
    },

    /** Choose BEST, BETTER, GOOD on click*/
    chooseBestClick() {
      this.chooseBest= true,
      this.chooseBetter = false,
      this.chooseGood = false
    },
    chooseBetterClick() {
      this.chooseBest= false,
      this.chooseBetter = true,
      this.chooseGood = false
    },
    chooseGoodClick() {
      this.chooseBest= false,
      this.chooseBetter = false,
      this.chooseGood = true
    },

    /** Radio BEST, BETTER, GOOD on click*/
    radioBestOnclick(index) {
      this.singleVisionBest.forEach(product => {
        product.default = false
      })
      console.log("radioBestOnclick" + index)
      this.singleVisionBest[index].default = true
    },
    radioBetterOnclick(index) {
      this.singleVisionBetter.forEach(product => {
        product.default = false
      })
      this.singleVisionBetter[index].default = true
    },
    radioGoodOnclick(index) {
      console.log("radioGoodOnclick vo")
      this.singleVisionGood.forEach(product => {
        product.default = false
      })
      this.singleVisionGood[index].default = true
    },

    refresh() {
      this.items = ArrayUtils.clone(this.items);
    },
  },
}
</script>

<style scoped>
.custom-table {
  margin: 0px;
}

.custom-btn {
  font-weight: 550;
  width: 127px;
  height: 39px;
  font-size: 15px;
  line-height: 23px;
  font-family: 'Montserrat', sans-serif;
}

.th-text {
  font-weight: bold;
  font-size: 13px;
  line-height: 14px;
  padding: 1rem 0.3rem;
  background:#F6F6F6;
}

thead > tr > td {
  font-size: 15px;
  line-height: 23px;
  text-align: left;
  margin-bottom: 1rem;
  font-family: 'Montserrat', sans-serif;
  padding: 2px 0px;
}

tbody > tr > td {
  height: 40px !important;
}

.rank-title {
  font-weight: bold;
  font-size: 15px;
  line-height: 23px;
  text-align: left;
  margin-bottom: 1rem;
  font-family: 'Montserrat', sans-serif;
  padding: 2px 0px;
}

.img-countermat {
  height: 68px;
  width: 68px;
}
.img-replace {
  height: 18px;
  width: 18px;
  margin-left: 5px;
}
.margin-left {
  margin-left: 5px;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: table;
  transition: opacity .3s ease;
}

.modal-mask-confirm {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: hsla(0, 0%, 71%, 0.5);
  display: table;
  transition: opacity .3s ease;
}

.custom-modal-dialog {
  margin: 0 auto !important;
  max-width: 100% !important;
  display: flex;
  justify-content: center;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.custom-dialog {
  width: 1034px;
  height: 602px;
  background: white;
}

.custom-dialog-confirm {
  width: 467px;
  height: 283px;
  background: #4B4B4B;
}

.modal-custom-header {
  background: #E5F4FF;
  margin: 0px 0px 15px 0px;
  padding: 10px;
}

.modal-custom-body {
  background: white;
  margin: 0px;
  padding: 0px 25px;
}

.modal-custom-body-row {
  background: #f6f6f6;
  margin: 0px 25px;
  padding: 0px;
}

.modal-custom-product {
  background: white;
  margin: 0px 25px;
  min-height: 150px;
}

.category-btn {
  height: 56px;
}

.category-btn > button {
  min-width: 91px; min-height:28px; text-align:center; font-size:13px; line-height: 14px; max-width: 160px; max-height: 40px;
}

.choose-color {
  background:#006DB6;
}

.btn-add {
  background:#006DB6; width: 50px; height:28px; text-align:center; font-size:13px; line-height: 14px
}

.body-text-modal{
  text-align: left;
  font-size: 15px;
  line-height: 23px;
  color: black;
}

.body-text-modal-confirm{
  width: 294px;
  height: 56px;
  margin: 0 auto;
  text-align: center;
  padding: 60px 0px;
  margin-bottom: 20px;
  font-size: 15px;
  line-height: 25px;
  color: white;
}

.btn-modal {
  float: right;
}

.btn-modal-confirm{
  display: flex;
  justify-content: center;
}

.clear{
  clear: both;
}

.warning-text{
  text-align: right;
  font-size: 15px;
  line-height: 23px;
  color: #FF0000;
  padding-top: 8px;
}

.container {
  padding: 10px;
}

.text-center {
  padding: 0px;
}

.radio-item {
  display: inline-block;
  position: relative;
  padding: 0 6px;
}

.radio-item input[type='radio'] {
  display: none;
}

.radio-item label {
  color: black;
  font-weight: normal;
}

.radio-item label:before {
  content: " ";
  display: inline-block;
  position: relative;
  top: 5px;
  margin: 0 5px 0 0;
  width: 22px;
  height: 22px;
  border-radius: 11px;
  border: 1px solid black;
  background-color: transparent;
}

.radio-item input[type=radio]:checked + label:after {
  border-radius: 11px;
  width: 16px;
  height: 16px;
  position: absolute;
  top: 8px;
  left: 9px;
  content: " ";
  display: block;
  background: black;
}

.text-elipcise {
  word-break: break-word;
  overflow: hidden;
   display: -webkit-box;
   -webkit-line-clamp: 3; /* number of lines to show */
           line-clamp: 3; 
   -webkit-box-orient: vertical;
}

.text-elipcise-2 {
  word-break: break-word;
  overflow: hidden;
   display: -webkit-box;
   -webkit-line-clamp: 2; /* number of lines to show */
           line-clamp: 2; 
   -webkit-box-orient: vertical;
}

.div-elipcise {
  width: 100%;
  overflow: hidden;
  word-break: break-word;
  overflow: hidden;
   display: -webkit-box;
   -webkit-line-clamp: 2; /* number of lines to show */
           line-clamp: 2; 
   -webkit-box-orient: vertical;
}

td {
  word-break: break-word;
}

</style>