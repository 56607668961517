import { http } from "../plugin/http2-common";

class CategoryRankService {
  listByCategory(surveyId) {
    return http.get("/category-rank/list-by-category/" + surveyId);
  }
  saveAll(data) {
    return http.post("/category-rank/save-all", data);
  }
}

export default new CategoryRankService();
