import { http } from "../plugin/http2-common";

class CountermatService {
  listProductCountermatSingleVision(surveyId) {
    return http.get("/product/list-product-countermat-single-vision/" + surveyId);
  }
  saveProductCountermatSingleVision(data) {
    return http.post("/product/save-all-product-countermat-sv-pals", data);
  }

  listProductCountermatProgresiveLens(surveyId) {
    return http.get("/product/list-product-countermat-pals/" + surveyId);
  }
  saveProductCountermatProgresiveLens(data) {
    return http.post("/product/save-all-product-countermat-sv-pals", data);
  }

  listProductCountermatVoila(surveyId) {
    return http.get("/product/list-product-countermat-voila/" + surveyId);
  }

  listProductCountermatSeries(surveyId) {
    return http.get("/product/list-product-countermat-series/" + surveyId);
  }
  saveProductCountermatVoila(data) {
    return http.post("/product/save-all-product-countermat-voila", data);
  }
}

export default new CountermatService();
