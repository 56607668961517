/* eslint-disable */
<template>
  <div>
    <div class="common-department" :style="{top: headerHeight + 'px'}">
      <div class="row align-items-start" style="margin:0; padding:0">
        <div class="col-5" style="margin:0; padding:0">
          <table
            class="table-sm table custom-table"
            style="width: 100%"
            responsive
            sm
            borderless
          >
            <thead>
              <tr bgcolor="#F6F6F6">
                <th width="30%" class="th-text">
                  CATEGORY
                </th>
                <th width="15%" class="th-text">
                  DEFAULT
                </th>
                <th
                  width="40%" class="th-text"
                >
                  <div class="text-elipcise">
                    <CustomEditor value="1ST BOX" />
                  </div>
                </th>
                <th
                  width="15%" class="th-text"
                >
                  IMAGE
                </th>
              </tr>
            </thead>
            <tbody>
              <template v-if="spanSize == 0">
                <tr>
                  <td
                    :rowspan="1"
                    class="s"
                  >
                    <strong style="color: #006db6">{{ voilaCategory.name }}
                    </strong>
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr
                  v-for="(item, index) in voilaCountermat"
                  :key="item.id"
                >
                  <td
                    v-if="index === 0"
                    :rowspan="spanSize"
                    class="s"
                  >
                    <strong style="color: #006db6">{{ voilaCategory.name }}
                    </strong>
                  </td>
                  <td
                    :class="{ 'border-top': index > 0 }"
                    class="text-center"
                  >
                    <div v-if="item.default == true" class="radio-item">
                      <input id="ritema" type="radio" name="radio-options-EL-best" value="item.name" checked />
                      <label for="ritema"></label>
                    </div>
                  </td>
                  <td
                    :class="{ 'border-top': index > 0 }"
                  >
                    <!-- {{ item.name }} -->
                    <CustomEditor v-model="item.name" />
                  </td>
                  <td :class="{ 'border-top': index > 0 }">
                    <div
                      :id="`popover-image-${voilaCountermatType}-${index}`"
                      class="icon-img"
                    >
                      <img
                        v-if="item.countermatImage != null"
                        :src="IMAGE_API_PATH + item.countermatImage"
                        fluid
                        alt="Responsive image"
                      />
                    </div>
                    <ImagePopover
                      v-if="item.countermatImage != null"
                      :product-type="voilaCountermatType"
                      :current-row-index="index"
                      :current-name="item.name"
                      :current-image="IMAGE_API_PATH + item.countermatImage"
                    />
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
        <div class="col-3" style="margin:0; padding:0">
          <table
            class="table-sm table custom-table"
            style="width: 100%"
            responsive
            sm
            borderless
          >
            <thead>
              <tr bgcolor="#F6F6F6">
                <th width="20%" class="th-text">
                  DEFAULT
                </th>
                <th
                  width="80%" class="th-text"
                >
                  <div class="text-elipcise">
                    <CustomEditor value="2ND BOX" />
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in palCountermat"
                :key="item.id"
              >
                <td
                  :class="{ 'border-top': index > 0 }"
                  class="text-center"
                >
                  <div v-if="item.elifeCountermatDefault == true" class="radio-item">
                    <input id="ritema" type="radio" name="radio-options-EL-better" value="item.name" checked />
                    <label for="ritema"></label>
                  </div>
                </td>
                <td
                  :class="{ 'border-top': index > 0 }"
                >
                  <!-- {{ item.name }} -->
                  <CustomEditor v-model="item.name" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-4" style="margin:0; padding:0">
          <table
            class="table-sm table custom-table"
            style="width: 100%"
            responsive
            sm
            borderless
          >
            <thead>
              <tr bgcolor="#F6F6F6">
                <th width="15%" class="th-text">
                  DEFAULT
                </th>
                <th
                  width="40%" class="th-text"
                >
                  <div class="text-elipcise">
                    <CustomEditor value="3RD BOX" />
                  </div>
                </th>
                <th
                  width="15%" class="th-text"
                >
                  IMAGE
                </th>
                <th
                  width="30%"
                >
                  <b-button 
                    variant="outline-info custom-btn"
                    style="height: 36px !important; float: right; margin-right: 4%;"
                    @click="showModalEdit=true"
                  >
                    Edit content
                  </b-button>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in conventionalCategory.products"
                :key="item.id"
              >
                <td
                  :class="{ 'border-top': index > 0 }"
                  class="text-center"
                >
                  <div v-if="item.default == true" class="radio-item">
                    <input id="ritema" type="radio" name="radio-options-EL-good" value="item.name" checked />
                    <label for="ritema"></label>
                  </div>
                </td>
                <td
                  :class="{ 'border-top': index > 0 }"
                >
                  <!-- {{ item.name }} -->
                  <CustomEditor v-model="item.name" />
                </td>
                <td :class="{ 'border-top': index > 0 }">
                  <div
                    :id="`popover-image-${conventionalType}-${index}`"
                    class="icon-img"
                  >
                    <img
                      :src="IMAGE_API_PATH + item.countermatImage"
                      fluid
                      alt="Responsive image"
                    />
                  </div>
                  <ImagePopover
                    :product-type="conventionalType"
                    :current-row-index="index"
                    :current-name="item.name"
                    :current-image="IMAGE_API_PATH + item.countermatImage"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <!--######################### The Modal edit ###########################################################################-->
    <div v-if="showModalEdit">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog custom-modal-dialog" role="document">
              <div class="modal-content custom-dialog">
                <div
                  class="modal-body"
                  style="padding: 0px; margin: 0px 0px; overflow: auto"
                >
                  <b-row class="modal-custom-header">
                    <b-col sm="8" class="my-auto">
                      <div class="body-text-modal">
                        <strong>{{ voilaCategory.name }}</strong><br />
                        <strong>STEP 1: </strong><span>Select 1 product in Box 1 and Box 2. Selected product will be displayed in the default countermat.</span><br />
                        <strong>STEP 2: </strong><span>Upload or change Box 1 and Box 3 countermat images.</span>
                      </div>
                    </b-col>
                    <b-col sm="4" class="my-auto">
                      <div>
                        <div class="btn-modal">
                          <b-button
                            type="button"
                            variant="outline-info custom-btn btn-save"
                            @click="saveOnclick()"
                          >
                            Save
                          </b-button>
                          <b-button
                            type="button"
                            variant="outline-info custom-btn"
                            @click="showModalCancelChange = true"
                          >
                            Cancel
                          </b-button>
                        </div>
                        <div class="clear"></div>
                        <div v-if="hasError" class="warning-text">
                          <svg
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9.00977 0.726562C4.43555 0.726562 0.728516 4.43359 0.728516 9.00781C0.728516 13.582 4.43555 17.2891 9.00977 17.2891C13.584 17.2891 17.291 13.582 17.291 9.00781C17.291 4.43359 13.584 0.726562 9.00977 0.726562ZM9.97656 12.9434C9.97656 13.209 9.86523 13.4434 9.69141 13.6348C9.51562 13.8262 9.25391 13.9102 9 13.9199C8.75586 13.9316 8.47852 13.8027 8.30859 13.6348C8.13086 13.457 8.02344 13.1953 8.02344 12.9434V8.90039C8.02344 8.63672 8.13477 8.40039 8.30859 8.21094C8.48438 8.01953 8.74609 7.93555 9 7.92383C9.24414 7.91211 9.52149 8.04102 9.69141 8.21094C9.86914 8.38867 9.97656 8.65039 9.97656 8.90039V12.9434ZM9.97656 5.32617V5.36523C9.97656 5.54102 9.93164 5.70508 9.84375 5.85742C9.79297 5.92383 9.74219 5.98828 9.69141 6.05469C9.57031 6.17578 9.42578 6.25977 9.25977 6.30664C9.17383 6.31836 9.08594 6.33008 9 6.3418C8.86523 6.34766 8.74023 6.31836 8.62305 6.25781C8.50195 6.21875 8.39648 6.15039 8.30859 6.05664C8.21289 5.96875 8.14648 5.86328 8.10742 5.74219C8.04688 5.625 8.01758 5.5 8.02344 5.36523V5.32617C8.02344 5.15039 8.06836 4.98633 8.15625 4.83398C8.20703 4.76758 8.25781 4.70313 8.30859 4.63672C8.42969 4.51563 8.57422 4.43164 8.74023 4.38477C8.82617 4.37305 8.91406 4.36133 9 4.34961C9.13477 4.34375 9.25977 4.37305 9.37695 4.43359C9.49805 4.47266 9.60352 4.54102 9.69141 4.63477C9.78711 4.72266 9.85352 4.82813 9.89258 4.94922C9.95312 5.06641 9.98242 5.19141 9.97656 5.32617Z"
                              fill="#FF0000"
                            />
                          </svg>
                          <span style="padding-left: 5px">{{ message }}</span>
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                  <div>
                    <div class="row align-items-start modal-custom-body">
                      <div class="col border-secondary category-btn d-flex justify-content-center align-items-center">
                        <button
                          type="button"
                          class="btn btn-outline-info" 
                          disabled
                        >
                          <div class="text-elipcise-2">
                            <CustomEditor value="BOX 1" />
                          </div>
                        </button>
                      </div>
                      <div class="col border-secondary category-btn d-flex justify-content-center align-items-center">
                        <button
                          type="button"
                          class="btn btn-outline-info"
                          disabled 
                        >
                          <div class="text-elipcise-2">
                            <CustomEditor value="BOX 2" />
                          </div>
                        </button>
                      </div>
                      <div class="col border-secondary category-btn d-flex justify-content-center align-items-center">
                        <button
                          type="button"
                          class="btn btn-outline-info" 
                          disabled
                        >
                          <div class="text-elipcise-2">
                            <CustomEditor value="BOX 3" />
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div class="row align-items-start modal-custom-body-row">
                      <div class="col border-secondary"
                           style="background: #F6F6F6"
                      >
                        <table
                          class="table-sm table custom-table"
                          style="width: 100%"
                          responsive
                          sm
                          borderless
                        >
                          <thead>
                            <tr bgcolor="#F6F6F6">
                              <th width="30%" class="th-text">
                                DEFAULT
                              </th>
                              <th
                                width="45%" class="th-text"
                              >
                                PRODUCT
                              </th>
                              <th
                                width="25%" class="th-text"
                              >
                                IMAGE
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(item, index) in voilaCountermat"
                              :key="item.id"
                            >
                              <td
                                class="text-center"
                              >
                                <div class="radio-item" @click="radioBox1Onclick(index)">
                                  <input id="ritema" type="radio" :checked="item.default == true" />
                                  <label for="ritema"></label>
                                </div>
                              </td>
                              <td>
                                <div class="div-elipcise">
                                  <!-- {{ item.name }} -->
                                  <CustomEditor v-model="item.name" />
                                </div>
                              </td>
                              <td>
                                <img
                                  width="30px"
                                  height="30px"
                                  :src="IMAGE_API_PATH + item.countermatImage"
                                  fluid
                                />
                                <img
                                  class="img-replace"
                                  src="@/assets/icons/replace.png"
                                  fluid
                                  alt="Replace"
                                  @click="replaceVoilaImage(index)"
                                />
                                <input
                                  v-show="false"
                                  ref="voilaImage"
                                  class="choose-file"
                                  type="file"
                                  @change="chooseVoilaImage($event)"
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div class="col border-secondary"
                           style="background: #F6F6F6"
                      >
                        <table
                          class="table-sm table custom-table"
                          style="width: 100%"
                          responsive
                          sm
                          borderless
                        >
                          <thead>
                            <tr bgcolor="#F6F6F6">
                              <th width="30%" class="th-text">
                                DEFAULT
                              </th>
                              <th
                                width="60%" class="th-text"
                              >
                                PRODUCT
                              </th>
                              <th
                                width="10%" class="th-text"
                              >
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(item, index) in palCountermat"
                              :key="item.id"
                            >
                              <td
                                class="text-center"
                              >
                                <div class="radio-item" @click="radioBox2Onclick(index)">
                                  <input id="ritema" type="radio" :checked="item.elifeCountermatDefault == true" />
                                  <label for="ritema"></label>
                                </div>
                              </td>
                              <td>
                                <div class="div-elipcise">
                                  <!-- {{ item.name }} -->
                                  <CustomEditor v-model="item.name" />
                                </div>
                              </td>
                              <td>
                                <!-- <b-icon
                                  class="pointer"
                                  icon="x-circle"
                                  variant="danger"
                                  @click="removeProduct(index, item); removeRankType=betterRankType"
                                /> -->
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div class="col border-secondary"
                           style="background: #F6F6F6"
                      >
                        <table
                          class="table-sm table custom-table"
                          style="width: 100%"
                          responsive
                          sm
                          borderless
                        >
                          <thead>
                            <tr bgcolor="#F6F6F6">
                              <th width="30%" class="th-text">
                                DEFAULT
                              </th>
                              <th
                                width="45%" class="th-text"
                              >
                                PRODUCT
                              </th>
                              <th
                                width="25%" class="th-text"
                              >
                                IMAGE
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(item, index) in conventionalCategory.products"
                              :key="item.id"
                            >
                              <td
                                class="text-center"
                              >
                                <div class="radio-item" @click="radioBox3Onclick(index)">
                                  <input id="ritema" type="radio" :checked="item.default == true" />
                                  <label for="ritema"></label>
                                </div>
                              </td>
                              <td>
                                <div class="div-elipcise">
                                  <!-- {{ item.name }} -->
                                  <CustomEditor v-model="item.name" />
                                </div>
                              </td>
                              <td>
                                <img
                                  width="30px"
                                  height="30px"
                                  :src="IMAGE_API_PATH + item.countermatImage"
                                  fluid
                                />
                                <img
                                  class="img-replace"
                                  src="@/assets/icons/replace.png"
                                  fluid
                                  alt="Replace"
                                  @click="replaceConventionalImage()"
                                />
                                <input
                                  v-show="false"
                                  ref="conventionalImageEl"
                                  class="choose-file"
                                  type="file"
                                  @change="chooseConventionalImage($event)"
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!--######################### The Modal Edit All END ###########################################################################-->
    <!--######################### The Modal SAVE ALL ###########################################################################-->
    <div v-if="showModalSaveAll">
      <transition name="modal">
        <div class="modal-mask-confirm">
          <div class="modal-wrapper">
            <div class="modal-dialog" role="document">
              <div class="modal-content custom-dialog-confirm">
                <div class="modal-body">
                  <div class="body-text-modal-confirm">
                    <p>
                      All changes will be saved.<br />
                      Select “confirm” to continue.
                    </p>
                  </div>
                  <div class="btn-modal-confirm">
                    <button
                      type="button"
                      class="btn btn-info"
                      style="
                        background: #006db6;
                        margin-right: 20px;
                        width: 127px;
                        height: 40px;
                      "
                      @click="
                        saveAll();
                        showModalSaveAll = false;
                        showModalEdit = false;
                      "
                    >
                      Confirm
                    </button>
                    <button
                      type="button"
                      class="btn btn-light"
                      style="
                        color: #006db6;
                        margin-left: 20px;
                        width: 127px;
                        height: 40px;
                      "
                      @click="showModalSaveAll = false"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!--######################### The Modal SAVE All END ###########################################################################-->

    <!--######################### The Modal CANCEL ###########################################################################-->
    <div v-if="showModalCancelChange">
      <transition name="modal">
        <div class="modal-mask-confirm">
          <div class="modal-wrapper">
            <div class="modal-dialog" role="document">
              <div class="modal-content custom-dialog-confirm">
                <div class="modal-body">
                  <div class="body-text-modal-confirm">
                    <p>
                      All changes will be removed.<br />
                      Select “confirm” to continue.
                    </p>
                  </div>
                  <div class="btn-modal-confirm">
                    <button
                      type="button"
                      class="btn btn-info"
                      style="
                        background: #006db6;
                        margin-right: 20px;
                        width: 127px;
                        height: 40px;
                      "
                      @click="
                        reloadPage();
                        showModalCancelChange = false;
                        showModalEdit=false;
                      "
                    >
                      Confirm
                    </button>
                    <button
                      type="button"
                      class="btn btn-light"
                      style="
                        color: #006db6;
                        margin-left: 20px;
                        width: 127px;
                        height: 40px;
                      "
                      @click="showModalCancelChange = false"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!--######################### The Modal CANCEL END ###########################################################################-->

    <!--######################### The Modal DELETE ###########################################################################-->
    <div v-if="showModalDelete">
      <transition name="modal">
        <div class="modal-mask-confirm">
          <div class="modal-wrapper">
            <div class="modal-dialog" role="document">
              <div class="modal-content custom-dialog-confirm">
                <div class="modal-body">
                  <div class="body-text-modal-confirm">
                    <p>
                      {{ titleDeletedProduct }} will be deleted.<br />
                      Select “confirm” to continue.
                    </p>
                  </div>
                  <div class="btn-modal-confirm">
                    <button
                      type="button"
                      class="btn btn-info"
                      style="
                        background: #006db6;
                        margin-right: 20px;
                        width: 127px;
                        height: 40px;
                      "
                      @click="
                        showModalDelete = false;
                        removeConfirm();
                      "
                    >
                      Confirm
                    </button>
                    <button
                      type="button"
                      class="btn btn-light"
                      style="
                        color: #006db6;
                        margin-left: 20px;
                        width: 127px;
                        height: 40px;
                      "
                      @click="showModalDelete = false"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!--######################### The Modal DELETE END ###########################################################################-->
    <!--######################### The Modal ERRORR ###########################################################################-->
    <div v-if="showError">
      <transition name="modal">
        <div class="modal-mask-confirm">
          <div class="modal-wrapper">
            <div class="modal-dialog" role="document">
              <div class="modal-content custom-dialog-confirm">
                <div class="modal-body">
                  <div class="body-text-modal-confirm">
                    <p>{{ message }}<br /></p>
                  </div>
                  <div class="btn-modal-confirm">
                    <button
                      type="button"
                      class="btn btn-light"
                      style="
                        color: #006db6;
                        margin-left: 20px;
                        width: 127px;
                        height: 40px;
                      "
                      @click="showError = false"
                    >
                      OK
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!--######################### The Modal ERRORR END ###########################################################################-->
  </div>
</template>

<script>
import ArrayUtils from "../../common/ArrayUtils";
import Constants from "../../common/Constants";
import CustomEditor from "../../components/custom/CustomEditor.vue";
import { CONTEXT_PATH, LOAD_IMAGE_API_PATH } from "../../constants/Paths";
import ConfigurationService from "../../service/ConfigurationService";
import CountermatService from "../../service/CountermatService";
import UploadFileService from "../../service/UploadFileService";
import ImagePopover from "../common/ImagePopover.vue";

export default {
  name: "SingleVisionModule",
  components: {
    ImagePopover,
    CustomEditor
  },
  props: {
    reload: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      IMAGE_API_PATH: CONTEXT_PATH + LOAD_IMAGE_API_PATH,
      progress: 0,
      showError: false,
      editable: false,
      ranks: [],

      showModalEdit: false,
      showModalCancelChange: false,
      showModalDelete: false,
      showModalSaveAll: false,
      dataTable: [],
      voilaCategory: {},
      conventionalCategory: {},
      voilaCountermat: [],
      voilaDisabledCountermat: [],
      palCountermat: [],

      checkCategoryElife: "",

      spanSize: 0,
      voilaCountermatType: "VOILA",
      conventionalType: "CVT",

      replaceImageRankType: "",
      changeImageRownIndex: null,

      hasError: false,
      message: ""
    };
  },
  computed: {
    headerHeight() {
      return this.$store.state.common.theHeaderHeight
    }
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    turnOnEditable() {
      this.editable = true;
    },

    turnOffEditable() {
      this.editable = false;
      this.fetchData();
    },

    saveOnclick() {
      if (this.validateData()) {
        this.showModalSaveAll = true;
      }
    },
    reloadPage() {
      this.fetchData();
      this.hasError = false;
    },
    fetchData() {
      let surveyId = this.$store.state.survey.id;
      this.surveyId = surveyId <= 0 ? 1 : surveyId;
      ConfigurationService.select("RECOMMENDATION_PRODUCT_TYPE", surveyId)
          .then((response) => {
            var result = response.data.result;
            this.checkCategoryElife = result.configuration.configValue;

            CountermatService.listProductCountermatSeries(surveyId)
                .then((response) => {
                  var result = response.data.result;
                  console.log("JSON: " + JSON.stringify(result));
                  if (result) {
                    let tempLens = null;
                    if (this.checkCategoryElife == "E_LIFE_PRO") {
                      this.voilaCategory = result.voilaCategory;
                      tempLens = result.voilaCountermat;
                    } else {
                      this.voilaCategory = result.seriesCategory;
                      tempLens = result.seriesCountermat;
                    }

                    tempLens = this.cleanLens(tempLens, true);
                    this.voilaCountermat = tempLens[0];
                    this.voilaDisabledCountermat = tempLens[1];
                    if (this.voilaCountermat) {
                      this.spanSize =
                          this.spanSize > this.voilaCountermat.length
                              ? this.spanSize
                              : this.voilaCountermat.length;
                    }
                  
                    tempLens = this.cleanLens(result.conventionalLens.products, true);
                    result.conventionalLens.products = tempLens[0];
                    this.conventionalCategory = result.conventionalLens;
                  
                    tempLens = this.cleanLens(result.palCountermat, false);
                    this.palCountermat = tempLens[0];
                    if (this.palCountermat) {
                      this.spanSize =
                          this.spanSize > this.palCountermat.length
                              ? this.spanSize
                              : this.palCountermat.length;
                    }
                  }
                })
                .catch((e) => {
                  console.log(e);
                });
          })
          .catch((e) => {
            console.log(e);
          });
    },
    cleanLens(lenses, checkDefault) {
      let disabledLens = [];
      let enabledLens = [];
      let hasDefault = false;
      lenses.forEach(lens => {
        if (lens.disabled === true) {
          if (checkDefault) {
            lens.default = false;
          } else {
            lens.elifeCountermatDefault = false;
          }
          disabledLens.push(lens);
        } else {
          if (checkDefault) {
            if (lens.default === true) {
              if (hasDefault === true) {
                lens.default = false;
              } else {
                hasDefault = true;
              }
            }
          } else {
            if (lens.elifeCountermatDefault === true) {
              if (hasDefault === true) {
                lens.elifeCountermatDefault = false;
              } else {
                hasDefault = true;
              }
            }
          }
          enabledLens.push(lens);
        }
      });
      if (hasDefault === false && enabledLens.length > 0) {
        if (checkDefault) {
          enabledLens[0].default = true;
        } else {
          enabledLens[0].elifeCountermatDefault = true;
        }
      }
      return [enabledLens, disabledLens];
    },
    /** Radio BEST, BETTER, GOOD on click*/
    radioBox1Onclick(index) {
      this.voilaCountermat.forEach(product => {
        product.default = false
      })
      this.voilaCountermat[index].default = true
    },
    radioBox2Onclick(index) {
      this.palCountermat.forEach(product => {
        product.elifeCountermatDefault = false
      })
      this.palCountermat[index].elifeCountermatDefault = true
    },
    radioBox3Onclick(index) {
      this.conventionalCategory.products.forEach(product => {
        product.default = false
      })
      this.conventionalCategory.products[index].default = true
    },

    /**
     * Save all data
     */
    saveAll() {
      var data = {
        productVoilaCountermats: [...this.voilaCountermat, ...this.voilaDisabledCountermat],
        conventionalProduct: this.conventionalCategory.products[0],
        productPalCountermats: this.palCountermat,
      };

      CountermatService.saveProductCountermatVoila(data)
          .then((response) => {
            console.log(response.data.result.numerEffectedProduct);
            // this.editable = false;
            this.fetchData();
            // this.chooseBestClick();
          })
          .catch((e) => {
            console.log(e);
            // this.editable = false;
            this.fetchData();
            // this.chooseBestClick();
          });
    },

    validateData() {
      if (this.countermatImageIsNotExisted()) {
        console.log("error here");
        this.hasError = true;
        this.message = "Upload an image for all products.";
        return false;
      }
      this.hasError = false;
      return true;
    },

    countermatImageIsNotExisted() {
      return this.countermatImageNotExistIn(this.voilaCountermat);
    },

    countermatImageNotExistIn(productCountermat) {
      if (productCountermat === undefined || productCountermat.length == 0) {
        return false;
      }
      return (
          productCountermat.filter((product) => {
            return (
                product.countermatImage === undefined ||
                product.countermatImage === null
            );
          }).length > 0
      );
    },

    /* **Replace Image *** */
    replaceVoilaImage(index) {
      this.changeImageRownIndex = index;
      const elem = this.$refs.voilaImage[index];
      elem.click();
    },
    chooseVoilaImage(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createVoilaImage(files[0],  e.target);
    },
    createVoilaImage(file, input) {
      var index = this.changeImageRownIndex;
      var suffix = file['type'];
      if (suffix !== 'image/png' && suffix !== 'image/jpeg') {
        this.message = "The image format is incorrect, please upload the file in .png, .jpeg format";
        this.showError = true
        this.progress = 0;
        this.currentFile = undefined;
        input.value = null;
        return;
      }

      if (file.size > Constants.COUNTERMAT_SV_MAX_SIZE) {
        this.message = "the maximum size is 1MB, please check your image again";
        this.showError = true;
        this.progress = 0;
        this.currentFile = undefined;
        input.value = null;
        return;
      }
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        var image = new Image();
        image.src = reader.result;
        image.onload = () => {
          if(image.width > Constants.COUNTERMAT_SV_MAX_WIDTH_UPLOAD || image.height > Constants.COUNTERMAT_SV_MAX_HEIGHT_UPLOAD) {
            this.message = "The maximum dimension is 1430x1949px, please check your image again";
            this.showError = true;
            this.progress = 0;
            this.currentFile = undefined;
            input.value = null;
            return;
          }else {
            let loader = this.$loading.show(); 
            UploadFileService.uploadWithFileNamePrefix(
              file,
              "countermat_Voila_image",
              (event) => {
                this.progress = Math.round((100 * event.loaded) / event.total);
              }
            )
              .then((response) => {
                loader.hide();
                var imageName = response.data.name;
                console.log("imageName" + imageName);
                this.voilaCountermat[index].countermatImage = imageName;
              })
              .catch((error) => {
                loader.hide();
                console.log("error" + error);
                this.message = error;
                this.showError = true;
                this.progress = 0;
                input.value = null;
                console.log(this.message);
              });
              }
        };
      };
      reader.onerror = function (error) {
        console.log("error"+ error);
        this.message = error;
        this.showError = true;
        this.progress = 0;
        this.currentFile = undefined;
        input.value = null;
        return;
      };
    },
    replaceConventionalImage() {
      this.$refs.conventionalImageEl[0].click();
    },
    chooseConventionalImage(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      var file = files[0];
      var input = e.target;
      var suffix = file['type'];
      if (suffix !== 'image/png' && suffix !== 'image/jpeg') {
        this.message = "The image format is incorrect, please upload the file in .png, .jpeg format";
        this.showError = true
        this.progress = 0;
        this.currentFile = undefined;
        input.value = null;
        return;
      }

      if (file.size > Constants.COUNTERMAT_SV_MAX_SIZE) {
        this.message = "the maximum size is 1MB, please check your image again";
        this.showError = true;
        this.progress = 0;
        this.currentFile = undefined;
        input.value = null;
        return;
      }
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        var image = new Image();
        image.src = reader.result;
        image.onload = () => {
          if(image.width > Constants.COUNTERMAT_SV_MAX_WIDTH_UPLOAD || image.height > Constants.COUNTERMAT_SV_MAX_HEIGHT_UPLOAD) {
            this.message = "The maximum dimension is 1430x1949px, please check your image again";
            this.showError = true;
            this.progress = 0;
            this.currentFile = undefined;
            input.value = null;
            return;
          }else {
            let loader = this.$loading.show(); 
            // upload
            UploadFileService.uploadWithFileNamePrefix(
                files[0],
                "countermat_conv_image",
                (event) => {
                  this.progress = Math.round((100 * event.loaded) / event.total);
                }
            )
            .then((response) => {
              loader.hide();
              var imageName = response.data.name;
              console.log("imageName" + imageName);
              this.conventionalCategory.products[0].countermatImage = imageName;
            })
            .catch((error) => {
              loader.hide();
              console.log("error" + error);
              this.message = error;
              this.showError = true;
              this.progress = 0;
              console.log(this.message);
              input.value = null;
            });
          }
        };
      };
      reader.onerror = function (error) {
        console.log("error"+ error);
        this.message = error;
        this.showError = true;
        this.progress = 0;
        this.currentFile = undefined;
        input.value = null;
        return;
      };
      
    },

    refresh() {
      this.items = ArrayUtils.clone(this.items);
    },
  },
};
</script>

<style scoped>
.custom-table {
  margin: 0px;
}

.th-text {
  font-weight: bold;
  font-size: 13px;
  line-height: 14px;
  padding: 1rem 0.3rem;
  background: #f6f6f6;
  vertical-align: middle;
}

thead > tr > td {
  font-size: 15px;
  line-height: 23px;
  text-align: left;
  margin-bottom: 1rem;
  font-family: "Montserrat", sans-serif;
  padding: 2px 0px;
}

.rank-title {
  font-weight: bold;
  font-size: 15px;
  line-height: 23px;
  text-align: left;
  margin-bottom: 1rem;
  font-family: "Montserrat", sans-serif;
  padding: 2px 0px;
}

.btn-blue {
  border: 1px solid #006db6;
  background-color: white;
  color: #006db6;
  height: 39px;
  width: 127px;
  left: -360px;
  top: -1798px;
  border-radius: 6px;
  padding: 8px, 16px, 8px, 16px;
  font-size: 15px;
  cursor: pointer;
}

.custom-btn {
  font-weight: 550;
  width: 127px;
  height: 39px;
  font-size: 15px;
  line-height: 23px;
  font-family: "Montserrat", sans-serif;
}

.btn-save {
  margin-right: 10px;
}

.img-countermat {
  height: 68px;
  width: 68px;
}

.icon-img {
  margin: 0 auto;
}

.icon-img > img {
  border: .5px solid #848484;
  margin-left: 10px;
  width: 20px;
  height: 20px;
}

.img-replace {
  height: 18px;
  width: 18px;
  margin-left: 5px;
}

.margin-left {
  margin-left: 5px;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-mask-confirm {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: hsla(0, 0%, 71%, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.custom-modal-dialog {
  margin: 0 auto !important;
  max-width: 100% !important;
  display: flex;
  justify-content: center;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.custom-dialog {
  width: 1034px;
  height: 602px;
  background: white;
}

.custom-dialog-confirm {
  width: 467px;
  height: 283px;
  background: #4b4b4b;
}

.modal-custom-header {
  background: #e5f4ff;
  margin: 0px 0px 15px 0px;
  padding: 10px;
}

.modal-custom-body {
  background: white;
  margin: 0px;
  padding: 0px 25px;
}

.modal-custom-body-row {
  background: #f6f6f6;
  margin: 0px 25px;
  padding: 0px;
}

.modal-custom-product {
  background: white;
  margin: 0px 25px;
  min-height: 150px;
}

.category-btn {
  height: 56px;
}

.category-btn > button {
  width: 91px;
  height: 28px;
  text-align: center;
  font-size: 13px;
  line-height: 14px;
}

.choose-color {
  background: #006db6;
}

.btn-add {
  background: #006db6;
  width: 50px;
  height: 28px;
  text-align: center;
  font-size: 13px;
  line-height: 14px;
}

.body-text-modal {
  text-align: left;
  font-size: 15px;
  line-height: 23px;
  color: black;
}

.body-text-modal-confirm {
  width: 294px;
  height: 56px;
  margin: 0 auto;
  text-align: center;
  padding: 60px 0px;
  margin-bottom: 20px;
  font-size: 15px;
  line-height: 25px;
  color: white;
}

.btn-modal {
  float: right;
}

.btn-modal-confirm {
  display: flex;
  justify-content: center;
}

.clear {
  clear: both;
}

.warning-text {
  text-align: right;
  font-size: 15px;
  line-height: 23px;
  color: #ff0000;
  padding-top: 8px;
}
.th-desc {
  font-weight: 300;
}
.container {
  padding: 10px;
}

.radio-item {
  display: inline-block;
  position: relative;
  padding: 0 6px;
}

.radio-item input[type='radio'] {
  display: none;
}

.radio-item label {
  color: black;
  font-weight: normal;
}

.radio-item label:before {
  content: " ";
  display: inline-block;
  position: relative;
  top: 5px;
  margin: 0 5px 0 0;
  width: 22px;
  height: 22px;
  border-radius: 11px;
  border: 1px solid black;
  background-color: transparent;
}

.radio-item input[type=radio]:checked + label:after {
  border-radius: 11px;
  width: 16px;
  height: 16px;
  position: absolute;
  top: 8px;
  left: 9px;
  content: " ";
  display: block;
  background: black;
}
.text-elipcise {
  word-break: break-word;
  overflow: hidden;
   display: -webkit-box;
   -webkit-line-clamp: 3; /* number of lines to show */
           line-clamp: 3; 
   -webkit-box-orient: vertical;
}

.text-elipcise-2 {
  word-break: break-word;
  overflow: hidden;
   display: -webkit-box;
   -webkit-line-clamp: 2; /* number of lines to show */
           line-clamp: 2; 
   -webkit-box-orient: vertical;
}
.div-elipcise {
  width: 100%;
  overflow: hidden;
  word-break: break-word;
  overflow: hidden;
   display: -webkit-box;
   -webkit-line-clamp: 2; /* number of lines to show */
           line-clamp: 2; 
   -webkit-box-orient: vertical;
}
td {
  word-break: break-word;
}

</style>

