import { http } from "../plugin/http2-common";

class SurveyConfigurationService {
  list(surveyId) {
    return http.get("/survey-configuration/list/" + surveyId);
  }
  save(data) {
    return http.put("/survey-configuration/update", data);
  }
}

export default new SurveyConfigurationService();
